<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <h3 class="form-subtitle mb__12">Command type:</h3>
        <SegmentControl
            :segment-control-class="'segment-control_width_400 mb__12'"
            :items="{'TCP': 'TCP','POD': 'POD'}"
            v-model="command.type"
        />

        <div class="field-group field-group_type_select field-group_width_400 mb__24">
            <label class="field-group__label">Command</label>
            <datasource ref="localDataSource"
                        :group-field-="'category'"
                        :group--dir="'asc'"
                        :data="localData"
            >
            </datasource>
            <kwrapper-dropdownlist
                    :data-source-ref="'localDataSource'"
                    :data-text-field="'name'"
                    :data-value-field="'id'"
                    :group--template="'Group: #: data #'"
                    :fixed-group-template="'Fixed group: #: data #'"
                    :options-label="$t('Select _')"
                    :filter="'contains'"
                    :height="'320'"
            >
            </kwrapper-dropdownlist>
            <span class="field-group__icon icon"> <span class="icon__symbol"><SvgIcon class="icon" name="input__select"/></span> </span>
        </div>

        <button class="button button_view_base button_theme_primary button_width_400 mb__24"> Send</button>

        <h3 class="form-subtitle mb__12">Recently sent commands:</h3>
        <div class="card ptb__4">
            <table class="table">
                <thead>
                <tr>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Text</th>
                    <th>Time send</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <span class="label label_status_danger">Not delivered</span>
                    </td>
                    <td>tcp_answer</td>
                    <td>FLAGS:0;CODE:1;ANSWER:RTMP close command is sent to success</td>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <button class="button button_view_base button_theme_primary button_size_sm">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__resend_sm"/></span> </span>
                            Resend
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <span class="label label_status_danger">Not delivered</span>
                    </td>
                    <td>tcp</td>
                    <td>RTMP,OFF</td>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <button class="button button_view_base button_theme_primary button_size_sm">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__resend_sm"/></span> </span>
                            Resend
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <span class="label label_status_success">Delivered</span>
                    </td>
                    <td>tcp_answer</td>
                    <td>FLAGS:0;CODE:1;ANSWER:RTMP close command is sent to success</td>
                    <td>
                        <nobr>Apr 14, 2021 </nobr>
                        <br>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>
                        <button class="button button_view_base button_theme_primary button_size_sm">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__resend_sm"/></span> </span>
                            Resend
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </section>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitCommand_Templates',
        props: [
            'unitId',
        ],
        components: {
        },
        data() {
            return {
                command: {
                    type: 'TCP',
                },
                localData: [
                    // { id: -1,name: " ", category: " "},
                    {id: 10, name: "Tea0", category: "Beverages"},
                    {id: 11, name: "Tea1", category: "Beverages"},
                    {id: 12, name: "Tea2", category: "Beverages"},
                    {id: 13, name: "Tea3", category: "Beverages"},
                    {id: 14, name: "Tea4", category: "Beverages"},
                    {id: 15, name: "Tea5", category: "Beverages"},
                    {id: 16, name: "Tea6", category: "Beverages"},
                    {id: 17, name: "Tea7", category: "Beverages"},
                    {id: 18, name: "Tea8", category: "Beverages"},
                    {id: 19, name: "Tea9", category: "Beverages"},

                    {id: 20, name: "Ham0", category: "Food"},
                    {id: 21, name: "Ham1", category: "Food"},
                    {id: 22, name: "Ham2", category: "Food"},
                    {id: 23, name: "Ham3", category: "Food"},
                    {id: 24, name: "Ham4", category: "Food"},
                    {id: 25, name: "Ham5", category: "Food"},
                    {id: 26, name: "Ham6", category: "Food"},
                    {id: 27, name: "Ham7", category: "Food"},
                    {id: 28, name: "Ham8", category: "Food"},
                    {id: 29, name: "Ham9", category: "Food"},
                ]
            }
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        mounted() {
        }
    }
</script>